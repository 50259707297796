import getConfig from 'next/config';
import { useEffect, useRef, useState } from 'react';
import { Card, Clickable } from '@fabric-ds/react';

import { isMobile } from 'shared/util/mobile';
import { queryString } from 'shared/util/query';
import { thousandSeparated } from 'shared/util/formatting';
import { formatReadableMonthYear, parseISO } from 'shared/util/datetime-fns';
import { trackSearchClick } from 'shared/search/common/tracking';

import styles from './CheapestDestinations.module.css';

export type LowfareDestination = {
    origin: string;
    originCity: string;
    originCountry: string;
    destination: string;
    destinationCity: string;
    destinationCountry: string;
    departureDate: string;
    returnDate: string;
    price: number;
};

type CheapestDestinationsProps = {
    destinations: LowfareDestination[];
};

const {
    publicRuntimeConfig: { flightResultUrl },
} = getConfig();

const colors = [
    { bg: 'aqua-200', text: 'gray-800' },
    { bg: 'green-200', text: 'gray-800' },
    { bg: 'yellow-200', text: 'gray-800' },
    { bg: 'aqua-500', text: 'white' },
    { bg: 'red-300', text: 'gray-800' },
    { bg: 'blue-200', text: 'gray-800' },
    { bg: 'yellow-100', text: 'gray-800' },
    { bg: 'aqua-400', text: 'gray-800' },
    { bg: 'red-200', text: 'gray-800' },
    { bg: 'green-600', text: 'white' },
    { bg: 'blue-400', text: 'white' },
    { bg: 'bluegray-300', text: 'gray-800' },
];

const createFlightUrl = (item: LowfareDestination): string =>
    flightResultUrl +
    queryString({
        tripType: 'roundtrip',
        requestedOrigin: item.origin,
        requestedDestination: item.destination,
        requestedDepartureDate: item.departureDate,
        requestedReturnDate: item.returnDate,
        adults: 1,
        cabinType: 'economy',
    });

const isElementInViewport = (el: Element): boolean => {
    const rect = el.getBoundingClientRect();
    return rect.right > 0;
};

export const CheapestDestinations = ({ destinations }: CheapestDestinationsProps) => {
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const container = useRef<HTMLDivElement>();

    useEffect(() => {
        // Disable auto scrolling feature on small screens, and on hover or focus on larger screens.
        if (!autoScroll || isMobile()) return;

        const elementGapWidth = 16; // must match flex gap in CSS file
        const containerScrollWidth = container.current.scrollWidth;

        const interval = setInterval(() => {
            const first = container.current.firstElementChild;

            if (!isElementInViewport(first)) {
                container.current.appendChild(first);
                container.current.scrollTo(container.current.scrollLeft - first.clientWidth - elementGapWidth, 0);
            }
            if (container.current.scrollLeft !== containerScrollWidth) {
                container.current.scrollTo(container.current.scrollLeft + 1, 0);
            }
        }, 25);

        return () => clearInterval(interval);
    }, [autoScroll, isMobile]);

    const handleClick = (data: LowfareDestination) => {
        const item = {
            id: 'finntravel',
            type: 'Travel',
            // Due to legacy definitions in Pulse, `destination` is used instead of `arrivalCountry`
            destination: data.destinationCountry,
            arrivalCity: data.destinationCity,
            arrivalAirport: data.destination,
            departureCountry: data.originCountry,
            departureCity: data.originCity,
            departureAirport: data.origin,
            travelType: 'flight',
            tripType: 'roundtrip',
            departureDate: data.departureDate,
            returnDate: data.returnDate,
            numberOfAdults: 1,
            numberOfChildren: 0,
            cabin: 'economy',
        };

        // Pulse tracker id: TR_ev01
        trackSearchClick('Search flight', '/reise', 'flight', 'cheapestdestinations', item);
    };

    return (
        <section className="my-32">
            <h3>Rimelige reiser akkurat nå</h3>
            <div
                className={styles.horizontalScrollingItems}
                ref={container}
                onMouseOver={() => setAutoScroll(false)}
                onMouseOut={() => setAutoScroll(true)}
                onFocus={() => setAutoScroll(false)}
                onBlur={() => setAutoScroll(true)}>
                {destinations.map((data, idx) => (
                    <Card key={data.origin + data.destination} className={styles.horizontalScrollingItem}>
                        <div aria-owns={`cheapestDestinationCard${idx}`} />
                        <div className={`bg-${colors[idx].bg} text-${colors[idx].text} whitespace-normal p-24`}>
                            <h3 className="text-16 font-normal" id={`cheapestDestinationCard${idx}`}>
                                <Clickable
                                    href={createFlightUrl(data)}
                                    onClick={() => handleClick(data)}
                                    title={`${data.originCity} til ${data.destinationCity}`}
                                    className="text-current hover:no-underline focus:no-underline">
                                    <span>{data.originCity} - </span>
                                    <div className="text-22 font-bold pt-8">{data.destinationCity}</div>
                                </Clickable>
                            </h3>
                            <div className="mt-32 capitalize">{formatReadableMonthYear(parseISO(data.departureDate))}</div>
                        </div>
                        <div className="px-24 py-16">
                            <span>fra</span>
                            <div className="text-22 font-bold py-2">{thousandSeparated(data.price)} kr</div>
                            <span className="text-gray-500 text-14">per person</span>
                        </div>
                    </Card>
                ))}
            </div>
        </section>
    );
};
